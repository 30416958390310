@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wdth,wght@125,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gupter:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.fontdat {
  font-family: "Ubuntu Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.hr {
 background: #e3e2e7;
 height: 15px;
 margin-top: 10px;
 margin-bottom: 10px;
}

.hrfont {
  font-family: "Inconsolata", monospace;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "wdth" 125;
}

.product {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.paragraph {
  font-family: "Gupter", serif;
  font-weight: 400;
  font-style: normal;
}


.bold {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}